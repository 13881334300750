import React from 'react';
import { Container } from 'react-bootstrap';

const NotFound = () => {
    return (
      <Container>
        <div className="home-page-wrapper">
          <div className="home-container">
            <p>
              <img style={{width:'280px', marginTop: '30px'}} src='/no_signal.gif' />
            </p>
            <p>Sorry, the page you are looking for does not exist.</p>
          </div>
        </div>
      </Container>
    );
};

export default NotFound;