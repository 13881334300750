// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.terms-list {
  list-style: decimal;
}

.terms-list > li {
  margin-bottom: 1rem; /* Adds 1rem space only to the root list items */
}

.terms-list li::marker {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/Terms.css"],"names":[],"mappings":";AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB,EAAE,gDAAgD;AACvE;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["\n.terms-list {\n  list-style: decimal;\n}\n\n.terms-list > li {\n  margin-bottom: 1rem; /* Adds 1rem space only to the root list items */\n}\n\n.terms-list li::marker {\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
