import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import NavbarComponent from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Home from './pages/Home';
import Exchange from './pages/Exchange'; 
import Showcase from './pages/Showcase'; 
import NotFound from './pages/NotFound';
import Terms from './pages/Terms';
import { StoreProvider } from './contexts/StoreContext';
import { ProductsProvider } from './contexts/ProductsContext';

function App() {
  
  // Theme state
  const [isDarkTheme] = useState(() => {
    const storedTheme = localStorage.getItem('theme');
    return storedTheme ? storedTheme === 'dark' : true;
  });

  // Apply the selected theme to the document body
  useEffect(() => {
    document.body.setAttribute('data-bs-theme', isDarkTheme ? 'dark' : 'light');
  }, [isDarkTheme]);

  // Define routes for the main BeamBenders site
  return (
    <BrowserRouter>
      <StoreProvider>
        <ProductsProvider>
          <div className="theme-container no-sidebar">
            <NavbarComponent />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/exchange" element={<Exchange />} /> {/* Add /exchange route */}
              <Route path="/showcase" element={<Showcase />} /> {/* Add /exchange route */}
              {/* Fallback route for undefined paths (404 page) */}
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </div>
        </ProductsProvider>
      </StoreProvider>
    </BrowserRouter>
  );
}

export default App;
