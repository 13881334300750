// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.masonry-grid {
  display: flex;
  margin-left: -16px; /* Adjust this to match your gap */
  width: auto;
}

.masonry-grid-column {
  padding-left: 16px; /* Adjust this to match your gap */
  background-clip: padding-box;
}

.masonry-grid-column > div {
  margin-bottom: 16px; /* Space between items */
}`, "",{"version":3,"sources":["webpack://./src/pages/Exchange.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,kBAAkB,EAAE,kCAAkC;EACtD,WAAW;AACb;;AAEA;EACE,kBAAkB,EAAE,kCAAkC;EACtD,4BAA4B;AAC9B;;AAEA;EACE,mBAAmB,EAAE,wBAAwB;AAC/C","sourcesContent":["\n.masonry-grid {\n  display: flex;\n  margin-left: -16px; /* Adjust this to match your gap */\n  width: auto;\n}\n\n.masonry-grid-column {\n  padding-left: 16px; /* Adjust this to match your gap */\n  background-clip: padding-box;\n}\n\n.masonry-grid-column > div {\n  margin-bottom: 16px; /* Space between items */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
