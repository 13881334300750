// src/contexts/ProductsContext.js
import React, { createContext, useState, useEffect } from 'react';
import { fetchAllExchangeProducts } from '../services/productService';

export const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filterTags, setFilterTags] = useState([]);
  const limit = 10; // Default limit per page

  // Load more products function
  const loadMoreProducts = async () => {
    if (loading) return; // Prevent re-triggering if already loading

    console.log(`Attempting to load page ${currentPage} with limit ${limit}`);
    setLoading(true);

    try {
      const { products: newProducts, page } = await fetchAllExchangeProducts(currentPage, limit, filterTags);

      console.log(`Loaded ${newProducts.length} products for page ${page}`);
      setProducts((prevProducts) => [...prevProducts, ...newProducts]);

      // Determine if more products are available
      if (newProducts.length < limit) {
        console.log("No more products to load.");
        setHasMore(false);
      } else {
        setCurrentPage(page + 1); // Increment page for next load
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Reset products when filterTags change
  useEffect(() => {
    console.log(`Filters updated to: ${filterTags.join(', ')}`);
    setProducts([]); // Clear current products
    setCurrentPage(1); // Reset page to 1
    setHasMore(true); // Reset hasMore flag

    // Trigger initial load for the updated filters
    loadMoreProducts();
  }, [filterTags]); // Re-trigger when filterTags change

  return (
    <ProductsContext.Provider value={{ products, loading, error, hasMore, setFilterTags, loadMoreProducts }}>
      {children}
    </ProductsContext.Provider>
  );
};
