import React from 'react';
import { Container } from 'react-bootstrap';
import './Terms.css';

function Terms() {
  return (
    <>
      <Container>
        <div className="home-page-wrapper">
          <p className="hint-container-title">TERMS OF SERVICE</p>
          <p className="hint-container-title">Effective Date: Nov 23, 2024</p>
          <p>
            Welcome to BeamBenders! By accessing or using our platform at BeamBenders.com, you agree to be bound by the following terms of service (“Terms”).
            If you do not agree to these Terms, you may not access or use the platform.
          </p>

          <ol className="terms-list">
            <li>
              <strong>About BeamBenders</strong>
              <p>
                BeamBenders is an online marketplace where users can showcase or sell items, particularly CRT-related products. BeamBenders does not own, sell, 
                or ship items listed on the platform. Transactions, refunds, returns, and dispute resolution are the responsibility of the sellers and buyers.
              </p>
            </li>

            <li>
              <strong>Eligibility</strong>
              <ul>
                <li>Be at least 18 years old or have legal capacity under your jurisdiction.</li>
                <li>Agree to comply with these Terms and all applicable laws.</li>
              </ul>
            </li>

            <li>
              <strong>User Responsibilities</strong>
              <p>
                <strong>Sellers:</strong>
                <ul>
                  <li>Ensure your listings are accurate, complete, and lawful.</li>
                  <li>Handle all transactions, including payments, refunds, returns, and customer inquiries.</li>
                  <li>Resolve disputes with buyers in a timely and professional manner.</li>
                </ul>
                <strong>Buyers:</strong>
                <ul>
                  <li>Ensure you read and understand the details of an item before purchasing.</li>
                  <li>Direct all refund, return, and dispute inquiries to the seller.</li>
                </ul>
              </p>
            </li>

            <li>
              <strong>Prohibited Activities</strong>
              <ul>
                <li>Post or sell items of an adult nature, including pornography, sexually explicit material, or other inappropriate content.</li>
                <li>List or promote illegal, stolen, or hazardous items.</li>
                <li>Engage in fraud, misrepresentation, or abusive behavior.</li>
                <li>Violate intellectual property rights or post copyrighted content without authorization.</li>
                <li>Selling products that infringe on copyrights or other intellectual property rights. (e.g. Mig switch, dumpers, injectors, mod chips etc.)</li>
                <li>Sharing instructions for soft or hard modding and jailbreaking game consoles, while it may be legally permissible in some regions, it straddles a fine line and does not align with our terms of use.</li>
              </ul>
              <p>
                BeamBenders reserves the right to remove any content or listing that violates these Terms or our policies.
              </p>
            </li>

            <li>
              <strong>Content Ownership</strong>
              <ul>
                <li>
                  <strong>User-Generated Content:</strong> By posting content on BeamBenders, you grant us a non-exclusive, royalty-free, worldwide license to use, 
                  display, and distribute your content for platform purposes.
                </li>
                <li>
                  <strong>BeamBenders Content:</strong> The BeamBenders platform, branding, and associated content are owned by BeamBenders and may not be copied, 
                  reproduced, or redistributed without permission.
                </li>
              </ul>
            </li>

            <li>
              <strong>Liability Disclaimer</strong>
              <ul>
                <li>The accuracy or legality of listings posted by users.</li>
                <li>Any disputes, refunds, returns, or damages arising from transactions between buyers and sellers.</li>
                <li>BeamBenders does not guarantee the quality, authenticity, or condition of any items listed by sellers.</li>
              </ul>
            </li>

            <li>
              <strong>Dispute Resolution</strong>
              <p>
                All disputes related to a transaction are to be resolved between the buyer and seller. BeamBenders does not mediate disputes but reserves the right 
                to suspend or terminate accounts involved in fraudulent or abusive behavior.
              </p>
            </li>

            <li>
              <strong>Termination</strong>
              <p>
                BeamBenders reserves the right to suspend or terminate your account if you:
              </p>
              <ul>
                <li>Violate these Terms or any applicable law.</li>
                <li>Post prohibited or inappropriate content.</li>
                <li>Engage in fraud or abusive activity.</li>
              </ul>
            </li>

            <li>
              <strong>Modification of Terms</strong>
              <p>
                BeamBenders may update these Terms from time to time. Changes will be effective upon posting on the platform. Continued use of the platform constitutes 
                your acceptance of the updated Terms.
              </p>
            </li>

            <li>
              <strong>Contact Information</strong>
              <p>
                For questions or concerns about these Terms, please contact us at <a href="mailto:support@beambenders.com">support@beambenders.com</a>.
              </p>
              <p>
                By using BeamBenders, you acknowledge that you have read, understood, and agree to these Terms.
              </p>
            </li>
          </ol>
        </div>
      </Container>
    </>
  );
}

export default Terms;
