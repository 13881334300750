import React, { useState } from 'react';
import { Navbar, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import portalIcon from '../assets/portal.svg';
import exchangeIcon from '../assets/exchange.svg';
import './Navbar.css';
//import repoIcon from '../assets/repo.svg';

const phosphorUrl = process.env.REACT_APP_PHOSPHOR_URL;
const exchangeUrl = process.env.REACT_APP_EXCHANGE_URL;
//const repoUrl = process.env.REACT_APP_REPO_URL;

// Navbar.js
function NavbarComponent() {

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  const renderTooltip = (text) => (
    <Tooltip className="d-none d-lg-block">
      {text}
    </Tooltip> // Tooltip visible only on large screens
  );

  return (
    <Navbar className="navbar" expand="lg" expanded={isOpen}>
      <Navbar.Brand as={Link} to="/">
        <img className="nav-logo" alt="BeamBenders Logo" src='/logo.png'/>
        <span className="site-name"><img src='/beambenders_logo.png' alt="BeamBenders Logo" style={{height: '20px'}}/></span>
      </Navbar.Brand>
      <Navbar.Toggle 
        aria-controls="basic-navbar-nav"
        onClick={toggleNavbar}
        className={isOpen ? 'navbar-toggler open' : 'navbar-toggler'}
      >
        <div className="hamburger-icon">
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto" onClick={closeNavbar}>
          <OverlayTrigger
            placement="bottom"
            overlay={renderTooltip('Phosphor Portal')}
          >
            <Nav.Link as="a" href={phosphorUrl}>
              <img
                src={portalIcon}
                alt="Phosphor Portal"
                style={{ height: '2.2rem', marginRight: '8px' }}
              />
              <span className="nav-link-text">Phosphor Portal</span>
            </Nav.Link>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={renderTooltip('Electron Exchange')}
          >
            <Nav.Link as="a" href={exchangeUrl}>
              <img
                src={exchangeIcon}
                alt="Electron Exchange"
                style={{ height: '2.2rem', marginRight: '8px' }}
              />
              <span className="nav-link-text">Electron Exchange</span>
            </Nav.Link>
          </OverlayTrigger>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarComponent;