import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {

    return (
      <Container>
        <footer className="footer-wrapper">
          <div className="footer">
              BeamBenders.com is owned by <a href="https://atlantdesigns.com" target="_blank" rel="noopener noreferrer">AtlantDesigns Inc.</a>
          </div>
          <div className="policy">

          </div>
          <div className="copyright">
          Copyright © 2024 BeamBenders
          </div>
        </footer>
      </Container>
    );
}

export default Footer;