// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page-wrapper {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: calc(var(--navbar-height) + 1rem);
  padding-bottom: 2rem;
  min-height: calc(100vh - var(--navbar-height));
}

.home-container {
  text-align: center;
  margin-bottom: 60px;
}

.home-logo {
  padding: 20px;
  width: 100%;
  max-width: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,cAAc;EACd,sBAAsB;EACtB,8BAA8B;EAC9B,sBAAsB;EACtB,8CAA8C;EAC9C,oBAAoB;EACpB,8CAA8C;AAChD;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".home-page-wrapper {\n  box-sizing: border-box;\n  width: 100%;\n  margin: 0 auto;\n  flex-direction: column;\n  justify-content: space-between;\n  box-sizing: border-box;\n  padding-top: calc(var(--navbar-height) + 1rem);\n  padding-bottom: 2rem;\n  min-height: calc(100vh - var(--navbar-height));\n}\n\n.home-container {\n  text-align: center;\n  margin-bottom: 60px;\n}\n\n.home-logo {\n  padding: 20px;\n  width: 100%;\n  max-width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
