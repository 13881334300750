import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { Container, Row, Col } from 'react-bootstrap';
import { StoreContext } from '../contexts/StoreContext';
import StoreCard from '../components/StoreCard';
import './Home.css';

const Home = () => {
  const { stores, loading, error } = useContext(StoreContext);

  return (
    <>
      <Container>
        <ToastContainer />
        <div className="home-page-wrapper">
          <div className="home-container">
            <p>
              <img  src='./beambenders_logo.gif'/>
            </p>
            <p>
              <img className="home-logo" src='./beambenders_logo.png'/>
            </p>
            <p>(<i><b>n.</b></i>) The ones who know how to bend electrons*.</p>
          </div>

          {/* Display Store Cards */}
          <div className="store-list">
            {loading && <p>Loading stores...</p>}
            {error && <p>{error}</p>}
            {!loading && !error && (
              <Row>
                {stores && stores.map((store, index) => (
                  <Col key={index} xs={12} md={12} lg={4}>
                    <StoreCard store={store} onImageClick={() => console.log(store.name)} />
                  </Col>
                ))}
              </Row>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Home;