import React, { createContext, useState, useEffect } from 'react';

export const StoreContext = createContext();

const API_URL = process.env.REACT_APP_API_URL;

export const StoreProvider = ({ children }) => {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const response = await fetch(`${API_URL}/stores/public`);
        if (!response.ok) {
          throw new Error('Failed to fetch stores');
        }

        const data = await response.json();

        console.log ('Stores Data: ' + JSON.stringify(data));
        setStores(data.stores.slice(0, 6)); // Limit to 6 stores
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStores();
  }, []);

  return (
    <StoreContext.Provider value={{ stores, loading, error }}>
      {children}
    </StoreContext.Provider>
  );
};
