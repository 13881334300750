import { Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './StoreCard.css';

const ELECTRON_URL = process.env.REACT_APP_ELECTRON_URL;

function StoreCard(props) {
  const { store } = props;

  return (
    <a href={`${ELECTRON_URL}/${store.handle}`} style={{ textDecoration: 'none', color: 'inherit' }}>
      <Card className="store-card mb-3 store-background-image"
        style={{
          backgroundImage: `url(${store.background_image || '/placeholder-background.gif'})`,
          cursor: 'pointer'
        }}
      >
        {/* Circular Logo in the Top Right */}
        {store.logo && (
          <img
            src={store.logo}
            alt={`${store.name} logo`}
            className="store-logo"
          />
        )}

        <Card.Body className="store-card-body">
          {/* Store Name */}
          <strong className="store-name">{store.name}</strong>

          {/* Store Description */}
          <Card.Text className="store-description">
            {store.description}
          </Card.Text>

          {/* Visit Store Button */}
          <div className="store-buttons">
            <button className="btn btn-primary" style={{ width: '100%' }}>
              Tune In
            </button>
          </div>
        </Card.Body>
      </Card>
    </a>
  );
}

export default StoreCard;
