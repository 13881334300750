// src/components/ProductCard.js
import React from 'react';
import { Card } from 'react-bootstrap';
import './ProductCard.css';

const ELECTRON_URL = process.env.REACT_APP_ELECTRON_URL;

const ProductCard = ({ product }) => {
  const handleClick = () => {
    window.location.href = `${ELECTRON_URL}/${product.store_handle}/product/${product._id}`;
  };

  return (
    <Card className="product-card" onClick={handleClick} style={{ position: 'relative' }}>
      {/* Store logo in top-right corner */}
      {product.store_logo && (
        <img
          src={product.store_logo}
          alt={`${product.storeName} logo`}
          className="store-logo"
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
          }}
        />
      )}
      <Card.Img variant="top" src={product.images?.[0]?.cropped_url || '/placeholder-image.jpg'} />
      <Card.Body>
        <Card.Title>{product.name}</Card.Title>
        <Card.Text>{product.description}</Card.Text>
        <Card.Text>${product.price/100}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
